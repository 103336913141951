import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ImgLogoRdmps from "images/logo-rdmps.svg";
import ImgTwitter from "images/ic-twitter.svg";
import ImgInstagram from "images/ic-insta.svg";
import ImgFacebook from "images/ic-face.svg";
import ImgPayments from "images/payments.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "40px",
    margin: "10px 0px",
  },
  roadLink: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "@media(max-width: 768px)": {
      justifyContent: "center",
    },
  },
  socialLinksDiv: {
    textAlign: "center",
    "@media(max-width: 768px)": {
      margin: "15px 0px",
    },
  },
  betLinkDiv: {
    textAlign: "right",
    "@media(max-width: 768px)": {
      textAlign: "center",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={{ background: "#333333" }}>
      <Container
        maxWidth="lg"
        style={{ fontFamily: "poppins", paddingTop: "3.5rem" }}
      >
        <Grid
          container
          justify="space-between"
          spacing={2}
          style={{ color: "white", marginBottom: 50 }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <p style={{ margin: 0, fontSize: "1rem", lineHeight: "1.8" }}>
              Social
            </p>
            <a
              href="https://www.facebook.com/casadeapostas.bra"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Facebook
            </a>
            <a
              href="https://twitter.com/casadeapostastv"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/casadeapostas"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Instagram
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <p style={{ margin: 0, fontSize: "1rem", lineHeight: "1.8" }}>
              Notícias
            </p>
            <a
              href="https://blog.casadeapostas.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Blog do Casa
            </a>
            <a
              href="https://casadeapostas.com/pt-br/Page/Promos"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Promoções
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <p style={{ margin: 0, fontSize: "1rem", lineHeight: "1.8" }}>
              Políticas
            </p>
            <Link
              to="/termos"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Termos e Condições
            </Link>
            <Link
              to="/politicas"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Políticas de Privacidade
            </Link>
            <a
              href="https://casadeapostas.com/pt-br/Page/Faq"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              FAQ
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <p style={{ margin: 0, fontSize: "1rem", lineHeight: "1.8" }}>
              Empresa
            </p>
            <a
              href="https://casadeapostas.com/pt-br/Page/About"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.8",
                opacity: "0.5",
              }}
            >
              Sobre a empresa
            </a>
          </Grid>
        </Grid>
      </Container>
      <div style={{ borderTop: "1px solid #3F3F3F" }}></div>
      <Container
        maxWidth="lg"
        style={{ fontFamily: "poppins", paddingTop: "1.6rem" }}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ paddingBottom: 30 }}
        >
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.socialLinksDiv}>
              <a
                href="https://www.facebook.com/casadeapostas.bra"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", lineHeight: 0 }}
              >
                <img
                  alt="Facebook"
                  src={ImgFacebook}
                  style={{ paddingRight: "0.652rem" }}
                />
              </a>
              <a
                href="https://twitter.com/casadeapostas"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", lineHeight: 0 }}
              >
                <img
                  alt="Twitter"
                  src={ImgTwitter}
                  style={{ paddingRight: "0.652rem" }}
                />
              </a>
              <a
                href="https://www.instagram.com/casadeapostas"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", lineHeight: 0 }}
              >
                <img alt="Instagram" src={ImgInstagram} />
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
