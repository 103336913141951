/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
import React, { useState, useRef, Fragment, useEffect } from "react";
import logo from "images/logo";
import logoSmall from "images/logoSmall";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { OutlinedButton } from "../config/theme";
import ScrollLink from "./ScrollLink";

const useStyles = makeStyles((theme) => ({
  menuSandwich: {
    cursor: "pointer",
    display: "none",
    position: "relative",
    width: 30,
    height: 30,
    zIndex: 2,
    "& span": {
      borderTop: (props) =>
        props.menuOpen ? "2px solid #7ED321" : "2px solid white",
      width: 30,
      display: "block",
      marginBottom: (props) => (props.menuOpen ? 0 : 8),
      marginTop: (props) => (props.menuOpen ? -1 : 0),
      position: (props) => (props.menuOpen ? "absolute" : "unset"),
      top: (props) => (props.menuOpen ? "50%" : "unset"),
    },
    "& span:nth-child(1)": {
      transform: (props) => (props.menuOpen ? "rotate(45deg)" : "unset"),
    },
    "& span:nth-child(2)": {
      display: (props) => (props.menuOpen ? "none" : "block"),
    },
    "& span:nth-child(3)": {
      transform: (props) => (props.menuOpen ? "rotate(-45deg)" : "unset"),
    },
    "@media(max-width: 374px)": {
      display: "block",
    },
  },
  checkMenu: {
    display: "none",
  },
  dropDownMenu: {
    backgroundColor: "#17252C",
    position: "absolute",
    top: "100%",
    left: -32,
    right: -32,
    overflow: "hidden",
    zIndex: 1,
    listStyleType: "none",
    padding: 0,
    height: (props) => (props.menuOpen ? "unset" : 0),
    "& li": {
      padding: 10,
    },
  },
  desktopMenu: {
    display: "none",
    "@media(min-width: 375px)": {
      display: "block",
    },
  },
  overlapMenu: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#ffffffeb",
    transition: "all ease-in-out 0.3s",
    opacity: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    "& ul": {
      listStyle: "none",
      padding: 0,
      "& li a": {
        fontFamily: "Source Sans Pro",
        textDecoration: "none",
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#333333",
      },
    },
  },
  logo: {
    content: `url(${logo})`,
    "@media(max-width: 500px)": {
      content: `url(${logoSmall})`,
    },
  },
  outlinedButton: {
    "@media(max-width: 500px)": {
      minWidth: '95px',
    },
  }
}));

export default function NavBar({ OverlapMenuContent }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles({ menuOpen });
  const menuBtn = useRef(null);
  const overlapMenuRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  function handleMenuState() {
    setMenuOpen(!menuOpen);
  }

  function setOverlapMenuDisplay(display) {
    if (display === "flex") {
      overlapMenuRef.current.style.display = display;
      setTimeout(() => {
        overlapMenuRef.current.style.opacity = 1;
      }, 10);
    } else {
      overlapMenuRef.current.style.opacity = 0;
      setTimeout(() => {
        overlapMenuRef.current.style.display = display;
      }, 301);
    }
  }

  function handleSignUpBtn() {
    if (location.pathname !== "/") {
      history.push("/?to_signup=true");
    }
  }

  useEffect(() => {
    const body = document.querySelector("body");
    if (menuOpen) {
      window.scrollTo(0, 0);
      body.style.overflow = "hidden";
      setOverlapMenuDisplay("flex");
    } else {
      body.style.overflow = "auto";
      setOverlapMenuDisplay("none");
    }
  }, [menuOpen]);

  return (
    <Fragment>
      <div className={classes.overlapMenu} ref={overlapMenuRef}>
        <OverlapMenuContent handleMenuState={handleMenuState} />
      </div>
      <div
        style={{
          position: "absolute",
          top: 24,
          left: 32,
          right: 32,
          zIndex: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!menuOpen ? (
          <Link to="/" style={{ textDecoration: "none" }}>
            <img className={classes.logo} alt="logo" />
          </Link>
        ) : (
          <div style={{ width: 202, height: 38.63 }} />
        )}
        <div
          className={classes.menuSandwich}
          onClick={handleMenuState}
          onKeyDown={handleMenuState}
        >
          <span />
          <span />
          <span />
        </div>
        <div className={classes.desktopMenu}>
          <ScrollLink to="registration-form" onClick={handleSignUpBtn}>
            <OutlinedButton className={classes.outlinedButton}>Cadastre-se</OutlinedButton>
          </ScrollLink>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <span
              style={{
                verticalAlign: "middle",
                fontSize: "1.125rem",
                fontWeight: "bold",
                color: "white",
                marginLeft: 20,
              }}
            >
              Entrar
            </span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}
