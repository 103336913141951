import axiosClient from './axiosClient.js';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Authenticate the user
 * @param {Object} user - The user trying to sign in
 * @param {string} user.email - User email.
 * @param {string} user.password - User password.
 */
function signIn(user) {
  // Need to use default axios lib because i don't want
  //  interceptors to work for this request in particular
  //  method because the way rails returns 401 when e-mail
  //  and password don't match.
  return axios.post(
    '/users/sign_in',
    { user: user },
    { headers: { Accept: 'application/json' } }
  );
}

/**
 * Create an user account
 * @param {Object} user
 * @param {File} user.avatar
 * @param {string} user.name
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function signUp(user) {
  let formData = new FormData();
  formData.append('user[avatar]', user.avatar || "");
  formData.append('user[name]', user.name);
  formData.append('user[email]', user.email);
  formData.append('user[password]', user.password);
  formData.append('user[password_confirmation]', user.password_confirmation);
  return axiosClient.post('/users', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * Request a password reset email
 * @param {Object} user
 * @param {string} user.email
 */
function requestPasswordReset(user) {
  return axiosClient.post('/users/password', { user: user });
}

/**
 * Reset user password
 * @param {Object} user
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function resetPassword(user) {
  const query = new URLSearchParams(window.location.search);
  return axiosClient.put("/users/password", {
    user: { ...user, reset_password_token: query.get("reset_password_token") },
  });
}

/**
 * Create an applicant
 * @param {Object} applicant
 * @param {string} applicant.name
 * @param {string} applicant.email
 * @param {string} applicant.phone_number
 * @param {string} applicant.country
 * @param {string} applicant.state
 * @param {string} applicant.ca_user
 * @param {string} applicant.instagram_url
 * @param {string} applicant.twitter_url
 * @param {string} applicant.facebook_url
 */
function registerApplicant(applicant) {
  let formData = new FormData();
  formData.append('applicant[name]', applicant.name);
  formData.append('applicant[email]', applicant.email);
  formData.append('applicant[cpf]', applicant.cpf);
  formData.append('applicant[partner_id]', applicant.partner_id);
  formData.append('applicant[birthdate]', applicant.birthdate);
  formData.append('applicant[password]', applicant.password);
  formData.append('applicant[password_confirmation]', applicant.password_confirmation);
  formData.append('applicant[phone_number]', applicant.phone_number);
  formData.append('applicant[country]', applicant.country);
  formData.append('applicant[state]', applicant.state);
  formData.append('applicant[city]', applicant.city);
  formData.append('applicant[website_channels]', applicant.website_channels);
  formData.append('applicant[statistic_data]', applicant.statistic_data_bin);
  formData.append('applicant[ca_user]', applicant.ca_user);
  formData.append('applicant[instagram_url]', applicant.instagram_url);
  formData.append('applicant[twitter_url]', applicant.twitter_url);
  formData.append('applicant[facebook_url]', applicant.facebook_url);
  // Group questions
  formData.append('applicant[betting_market_group_question]', applicant.betting_market_group_question);
  const socialMediaGroupQuestions = trueKeysArray(applicant.social_media_group_question);
  const profileTypeGroupQuestions = trueKeysArray(applicant.profile_type_group_question);
  socialMediaGroupQuestions.forEach(question => {
    formData.append('applicant[social_media_group_question][]', question);
  });
  profileTypeGroupQuestions.forEach(question => {
    formData.append('applicant[profile_type_group_question][]', question);
  });
  formData.append('applicant[paid_traffic_group_question]', applicant.paid_traffic_group_question);
  
  // Related questions
  // Betting market
  formData.append(
    'applicant[betting_time_betting_market_group]',
    applicant.betting_market_related_group_questions?.bettingTime
  );

  formData.append(
    'applicant[affiliate_another_house_betting_market_group]',
    applicant.betting_market_related_group_questions?.affiliateAnotherHouse
  );

  formData.append(
    'applicant[work_with_sports_betting_market_group]',
    applicant.betting_market_related_group_questions?.workWithSportsBetting
  );

  // Social media
  formData.append(
    'applicant[how_many_followers_social_media_group]',
    applicant.social_media_related_group_questions?.howManyFollowers
  );

  formData.append(
    'applicant[social_networks_social_media_group]',
    applicant.social_media_related_group_questions?.socialNetworks
  );

  // Profile type
  formData.append(
    'applicant[segment_profile_type_group]',
    applicant.profile_type_related_group_questions?.segment
  );

  formData.append(
    'applicant[what_your_profile_type_group]',
    applicant.profile_type_related_group_questions?.whatYourProfile
  );

  // Paid traffic
  formData.append(
    'applicant[traffic_type_paid_traffic_group]',
    applicant.paid_traffic_related_group_question?.whatTypePaidTraffic
  );
  
  formData.append('applicant[reason_to_affiliate]', applicant.reason_to_affiliate);
  formData.append('applicant[agree_with_terms]', applicant.agree_with_terms);
  formData.append('applicant[operation_type_id]', applicant.operation_type);

  return axiosClient.post('/adm/applicants', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function trueKeysArray(obj) {
  return Object.keys(obj).filter(key => obj[key]);
}

/**
 * Update partner_id
 * @param {Object} user - The user
 * @param {string} user.email - User email.
 * @param {string} user.partner_id - User password.
 */
function updatePartner(user) {
  const formData = new FormData();
  formData.append('user[email]', user.email);
  formData.append('user[partner_id]', user.partner_id);
  return axiosClient.post('/adm/users/set_partner', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

const AuthorizationClient = {
  signIn,
  signUp,
  requestPasswordReset,
  resetPassword,
  registerApplicant,
  updatePartner,
};

export default AuthorizationClient;
