import React from "react";
import { Grid, Typography } from "@material-ui/core";
import logoTriangles from 'images/logo-triangles.svg';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root:{
    marginLeft: '-10px;',
  },
  item: {
    display: 'flex;',
    alignItems: 'center;',
    marginBottom: '5px;',
  },
  img: {
    height: '25px;',
  },
  title: {
    fontSize: '1.6rem',
    color: '#333333 !important',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    marginLeft: '5px;',
  },
}));

const FormSubtitle = ({ title = 'Title' }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item className={classes.item}>
          <img className={classes.img} src={logoTriangles} alt="logo" />
          <Typography className={classes.title} variant="h6">{title}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default FormSubtitle;