/* eslint-disable react/jsx-fragments */
import React, { useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import NavBar from "../components/NavBar";
import bgImage from "images/header-background.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
}));

export default function Politics({ politicsContent, publicView }) {
  const politicsContentRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    politicsContentRef.current.innerHTML = politicsContent;
  }, []);

  function OverlapMenuContent() {
    return (
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/login">Entrar</Link>
        </li>
        <li>
          <a
            href="https://casadeapostas.com/pt-br/Page/Terms"
            target="_blank"
            rel="noreferrer"
          >
            Termos e Condições
          </a>
        </li>
        <li>
          <a
            href="https://casadeapostas.com/pt-br/Page/Faq"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
        </li>
      </ul>
    );
  }

  return (
    <div className={classes.paper}>
      {publicView && <NavBar OverlapMenuContent={OverlapMenuContent} />}
      <Container
        maxWidth="sm"
        style={{
          fontFamily: "Source Sans Pro",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          ref={politicsContentRef}
          style={{
            width: "100%",
            marginTop: "4rem",
            height: "75vh",
            backgroundColor: "white",
            borderRadius: 3,
            padding: 20,
            overflow: "scroll",
          }}
        />
      </Container>
    </div>
  );
}

Politics.propTypes = {
  politicsContent: PropTypes.string,
};

Politics.defaultProps = {
  politicsContent: "",
};
