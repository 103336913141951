import React from 'react';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16,
          fontFamily: 'Source Sans Pro, Sans Serif',
        },
        body: {
          fontSize: 'unset',
          fontFamily: 'Source Sans Pro, Sans Serif',
          color: '#333333',
        },
        '@media(max-width: 1280px)': {
          html: {
            fontSize: 15,
          },
        },
        '@media(max-width: 960px)': {
          html: {
            fontSize: 14,
          },
        },
        '@media(max-width: 768px)': {
          html: {
            fontSize: 13,
          },
        },
        '@media(max-width: 360px)': {
          html: {
            fontSize: 11,
          },
        },
        '@media(max-width: 320px)': {
          html: {
            fontSize: 10,
          },
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'inherit',
        fontWeight: 'bold',
        borderRadius: 5,
      },
      label: {
        textTransform: 'none',
        fontSize: '1rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 425,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      green: '#7ED321',
      dark: '#333333',
      grey: '#F0F0F0',

    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    // body1: {
    //   fontFamily: 'Helvetica',
    // },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
    MuiMenuItem: {
      disableRipple: true,
    },
  },
});

export default function PublicThemeProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export const GreenButton = withStyles({
  root: {
    backgroundColor: theme.palette.common.green,
    // padding: '0.938rem 3.125rem',
    color: 'white',
    borderRadius: 30,
    minWidth: '10.165rem',
    height: '3rem',
    '&:hover': {
      backgroundColor: '#c0e697',
    },
  },
})(Button);

export const DarkButton = withStyles({
  root: {
    backgroundColor: theme.palette.common.dark,
    // padding: '0.938rem 3.125rem',
    color: 'white',
    borderRadius: 30,
    minWidth: '10.165rem',
    height: '3rem',
    '&:hover': {
      backgroundColor: '#565656',
    },
    '&.Mui-disabled': {
      color: '#a7a7a7',
    },
  },
})(Button);

export const OutlinedButton = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: '1px solid #FFFFFF4B',
    // padding: '0.938rem 3.125rem',
    padding: 0,
    minWidth: 170,
    height: 46,
    boxSizing: 'unset',
    color: 'white',
    borderRadius: 30,
  },
})(Button);

export const OutlinedBlueButton = withStyles({
  root: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.common.blue}`,
    // padding: '0.645rem 0.95rem',
    padding: 0,
    width: 170,
    height: 48,
    minWidth: 114,
    boxSizing: 'unset',
    color: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: '#d3f8ff',
    },
    fontSize: '1rem',
  },
})(Button);
