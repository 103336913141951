/* eslint-disable import/prefer-default-export */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: "crimson",
    position: "absolute",
    bottom: -20,
    left: 0,
    fontFamily: "Source Sans Pro",
    "@media(max-width: 360px)": {
      bottom: -14,
    },
  },
  errorRegistration: {
    color: "crimson",
    fontFamily: "Source Sans Pro"
  },
}));
export function RegistrationInputText(props) {
  const {
    label,
    placeholder,
    onChange,
    disabled,
    onKeyDown,
    name,
    error,
    value,
    mask,
    type,
    maxHeight,
    verticalSpacing,
    multiline,
    rowsMax,
    readOnly,
    rows,
    inputRef,
    onBlur,
    inputProps,
    InputLabelProps,
    defaultValue,
    forRegistration,
    required = false,
  } = props;

  const textFieldProps = {};
  if (value) textFieldProps.value = value;

  const border = `1px solid ${error ? "#ed143d" : "#DADADA"}`;
  const maskProp = mask ? { inputComponent: mask } : {};
  const classes = useStyles();

  let currentInputRef = useRef();
  if (inputRef) currentInputRef = inputRef;

  const registrationStyle = {
    root:{
      margin: 'unset',
    },
    input: {
      border,
      borderRadius: '10px',
    },
  };

  return (
    <div style={forRegistration ? registrationStyle.root : { marginBottom: verticalSpacing, position: "relative" }}>
      <InputLabel
      required={required}
        style={
          {
            fontFamily: "Source Sans Pro",
            fontSize: "0.75rem",
            fontWeight: "bold",
            color: "#333333",
            marginBottom: forRegistration ? '20px' : '0.625rem',
          }
      }
      >
        {label}
      </InputLabel>
      <TextField
        fullWidth
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        onKeyDown={onKeyDown}
        onChange={onChange}
        type={type}
        name={name}
        inputRef={currentInputRef}
        style={
          forRegistration
          ? registrationStyle.input 
          :
          {
            border,
            maxHeight,
            borderRadius: 3,
            padding: 5,
            backgroundColor: "white",
            boxSizing: "border-box",
          }
        }
        variant="standard"
        defaultValue={defaultValue}
        inputProps={inputProps}
        InputLabelProps={InputLabelProps}
        InputProps={{ disableUnderline: true, placeholder, ...maskProp }}
        { ...textFieldProps }
        onBlur={onBlur}
      />
      {error && <span className={forRegistration ? classes.errorRegistration : classes.errorMessage}>{error}</span>}
    </div>
  );
}

RegistrationInputText.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  rowsMax: PropTypes.string,
  rows: PropTypes.string,
  maxHeight: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  verticalSpacing: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  mask: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  forRegistration: PropTypes.bool,
};

RegistrationInputText.defaultProps = {
  error: "",
  value: "",
  mask: undefined,
  type: "text",
  maxHeight: "unset",
  rows: "",
  rowsMax: "",
  multiline: false,
  disabled: false,
  verticalSpacing: "1.0rem",
  onKeyDown: () => {},
};

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
export function RegistrationSelect(props) {
  const { label, placeholder, handleSelectChange, name, error, value, values, children, forRegistration, required = false } =
    props;

  const border = `1px solid ${error ? "#ed143d" : "#DADADA"}`;

  function SelectValues({ values: currentValues }) {

    return
  }

  const registrationStyle = {
    root:{
      marginTop: '-15px',
    },
    select: {
      border,
      borderRadius: '10px',
      height: '55px',
    },
  };

  return (
    <div style={ forRegistration ? registrationStyle.root : { marginBottom: "1.5rem", position: "relative" }}>
      {label && (
        <InputLabel
        required={required}
          style={{
            fontSize: "0.75rem",
            fontWeight: "bold",
            color: "#333333",
            fontFamily: "Source Sans Pro",
            marginBottom: forRegistration ? '20px' : '0.625rem',
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        style={forRegistration ? registrationStyle.select : { border, borderRadius: 3, padding: 5 }}
        name={name}
        input={<Input disableUnderline placeholder={placeholder} />}
        value={value}
        onChange={handleSelectChange}
        required={required}
        fullWidth
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {children}
      </Select>
      {error && (
        <span
          style={{
            color: "crimson",
            position: "absolute",
            bottom: -20,
            left: 0,
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
}

RegistrationSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  forRegistration: PropTypes.bool,
};

RegistrationSelect.defaultProps = {
  error: "",
  value: "",
  label: null,
};
