import React from 'react';
import { Link } from 'react-scroll';

export default function ScrollLink({ to, children, duration = 1300, onClick}) {
  function handleClick() {
    if (onClick) onClick();
  }
  
  return (
    <Link to={to} onClick={handleClick} duration={duration} smooth="easeInQuad" style={{ cursor: 'pointer', textDecoration: 'none' }}>
      {children}
    </Link>
  );
}
