import React from "react";
import { 
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root:{
    margin: '15px 0px',
  },
  title: {
    fontSize: '0.75rem',
    color: '#333333 !important',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    marginBottom: '10px',
  },
  labelOption:{
    '& span': {
      fontSize: '0.75rem',
    }
  }
}));

// Radio button custumer
const CustomRadio = withStyles({
  root: {
    color: '#B6B6B6',
    '&$checked': {
      color: '#7ED321',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RadioGroupField = ({ 
  title, 
  name,
  value, 
  onChange, 
  options = [], 
  orientation = "vertical",
  required = false,
  error,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel className={classes.title} component="legend" required={required} error={error}>{title}</FormLabel>
        <RadioGroup
          aria-label={title}
          name={name}
          value={value}
          onChange={onChange}
        >
          <Grid container direction={orientation === "horizontal" ? "row" : "column"}>
            {options.map((option, index) => (
              <Grid item key={index}>
                <FormControlLabel className={classes.labelOption} value={option.value} control={<CustomRadio />} label={option.label} />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      {error && (
        <span
          style={{
            color: "crimson",
            display: "block",
            left: 0,
          }}
        >
         {error}
        </span>
      )}
    </div>
  );
}

export default RadioGroupField;